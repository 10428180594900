import React, { useState, useEffect } from "react";
import { collection, setDoc, doc, updateDoc, getDocs, deleteDoc, query, where } from "firebase/firestore";
import { db } from "./firebase";
import { signOut } from "firebase/auth";
import { auth } from "./firebase";
import "./Dashboard.css"; // インポート

const Dashboard = ({ user }) => {
  const [records, setRecords] = useState([]);
  const [workType, setWorkType] = useState("現場");
  const [clockIn, setClockIn] = useState("09:00");
  const [clockOut, setClockOut] = useState("17:45");
  const [totalHours, setTotalHours] = useState(0);
  const [holidays, setHolidays] = useState([]);

  const [year, setYear] = useState(new Date().getFullYear());
  const [month, setMonth] = useState((new Date().getMonth() + 1).toString().padStart(2, '0'));
  const [day, setDay] = useState((new Date().getDate()).toString().padStart(2, '0'));

  const years = Array.from({ length: 20 }, (_, i) => (new Date().getFullYear() - 10 + i).toString());
  const months = Array.from({ length: 12 }, (_, i) => (i + 1).toString().padStart(2, '0'));

  const updateDays = (year, month) => {
    const lastDay = new Date(year, month, 0).getDate();
    const days = Array.from({ length: lastDay }, (_, i) => (i + 1).toString().padStart(2, '0'));
    return days;
  };

  useEffect(() => {
    fetchHolidays(year);
  }, [year]);

  useEffect(() => {
    const days = updateDays(year, month);
    if (parseInt(day) > days.length) {
      setDay(days[days.length - 1]);
    }
  }, [year, month]);

  useEffect(() => {
    fetchRecords();
  }, [user, year, month, holidays]);

  const fetchHolidays = async (year) => {
    try {
      const response = await fetch(`https://date.nager.at/api/v3/publicholidays/${year}/JP`);
      const data = await response.json();
      const holidayDates = data.map(holiday => holiday.date);
      setHolidays(holidayDates);
    } catch (error) {
      console.error("Error fetching holidays:", error);
    }
  };

  const fetchRecords = async () => {
    const startDate = `${year}-${month}-01`;
    const endDate = `${year}-${month}-${new Date(year, month, 0).getDate()}`;

    const recordsRef = collection(db, "time_cards", user.uid, "records");
    const q = query(recordsRef, where("date", ">=", startDate), where("date", "<=", endDate));
    const snapshot = await getDocs(q);
    const recordsData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

    const days = updateDays(year, month);
    const allRecords = days.map(day => {
      const date = `${year}-${month}-${day}`;
      const record = recordsData.find(record => record.date === date);
      const dayOfWeek = new Date(date).getDay();
      const isHoliday = holidays.includes(date);
      const isWeekend = dayOfWeek === 0 || dayOfWeek === 6;
      return record || {
        date,
        workType: isWeekend || isHoliday ? "" : null,
        clockIn: "",
        clockOut: ""
      };
    });

    setRecords(allRecords);
    calculateTotalHours(recordsData);
  };

  const handleClockIn = async () => {
    const date = `${year}-${month}-${day}`;
    await setDoc(doc(db, "time_cards", user.uid, "records", date), {
      date,
      workType,
      clockIn,
      clockOut: null
    }, { merge: true });
    fetchRecords();
  };

  const handleClockOut = async () => {
    const date = `${year}-${month}-${day}`;
    await updateDoc(doc(db, "time_cards", user.uid, "records", date), {
      clockOut
    });
    fetchRecords();
  };

  const handleLogout = async () => {
    await signOut(auth);
  };

  const handleDeleteRecord = async (recordId) => {
    await deleteDoc(doc(db, "time_cards", user.uid, "records", recordId));
    fetchRecords();
  };

  const handleCsvExport = () => {
    const csvContent = records.map(record => {
      const date = new Date(record.date);
      const day = date.getDate().toString().padStart(2, '0');
      return `${day},${record.workType},${record.clockIn},${record.clockOut}`;
    }).join('\n');

    const csvHeader = '日,出社区分,出勤時間,退勤時間\n';
    const csvData = csvHeader + csvContent;

    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `${String(year).slice(2)}${month}.csv`;
    link.click();
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { day: '2-digit', weekday: 'short' };
    return date.toLocaleDateString('ja-JP', options).replace('/', '日(').replace(' ', ')');
  };

  const calculateTotalHours = (recordsData) => {
    let totalMinutes = 0;

    recordsData.forEach(record => {
      if (record.clockIn && record.clockOut) {
        const clockInTime = new Date(`1970-01-01T${record.clockIn}:00`);
        const clockOutTime = new Date(`1970-01-01T${record.clockOut}:00`);
        const diff = (clockOutTime - clockInTime) / 1000 / 60 - 60; // subtract 60 minutes for break
        totalMinutes += diff;
      }
    });

    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    setTotalHours(`${hours}時間${minutes}分`);
  };

  const days = updateDays(year, month);

  return (
    <div className="dashboard">
      <h1>ようこそ, {user.email}さん</h1>
      <span className="logout-link" onClick={handleLogout}>ログアウト</span>
      <div className="csv-link" onClick={handleCsvExport}>CSV形式で出力</div>
      <div className="form-row">
        <div className="form-group">
          <label>年:</label>
          <select value={year} onChange={(e) => setYear(e.target.value)}>
            {years.map((yr) => (
              <option key={yr} value={yr}>{yr}</option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label>月:</label>
          <select value={month} onChange={(e) => setMonth(e.target.value)}>
            {months.map((mn) => (
              <option key={mn} value={mn}>{mn}</option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label>日:</label>
          <select value={day} onChange={(e) => setDay(e.target.value)}>
            {days.map((dy) => (
              <option key={dy} value={dy}>{dy}</option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label>出社区分:</label>
          <select value={workType} onChange={(e) => setWorkType(e.target.value)}>
            <option value="現場">現場</option>
            <option value="自社">自社</option>
            <option value="在宅">在宅</option>
          </select>
        </div>
      </div>
      <div className="form-row">
        <div className="form-group">
          <label>出勤時間:</label>
          <input type="time" value={clockIn} onChange={(e) => setClockIn(e.target.value)} />
        </div>
        <div className="form-group">
          <label>退勤時間:</label>
          <input type="time" value={clockOut} onChange={(e) => setClockOut(e.target.value)} />
        </div>
      </div>
      <div className="button-row">
        <button onClick={handleClockIn}>出勤</button>
        <button onClick={handleClockOut}>退勤</button>
      </div>

      <h2>勤怠記録（合計: {totalHours}）</h2>
      <ul>
        {records.map((record, index) => (
          <li key={index} className={getDayClass(record.date, record.workType, holidays)}>
            <div className="record-details">
              <div>{formatDate(record.date)}</div>
              <div>{record.workType}</div>
              <div>{record.clockIn}</div>
              <div>{record.clockOut}</div>
            </div>
            {record.id && <button onClick={() => handleDeleteRecord(record.id)}>削除</button>}
          </li>
        ))}
      </ul>
    </div>
  );
};

const getDayClass = (dateString, workType, holidays) => {
  const date = new Date(dateString);
  const dayOfWeek = date.getDay();
  const isHoliday = holidays.includes(dateString);
  if (isHoliday) {
    return 'holiday';
  }
  if (dayOfWeek === 0) {
    return 'sunday';
  }
  if (dayOfWeek === 6) {
    return 'saturday';
  }
  if (workType === null) {
    return 'weekday-no-record';
  }
  return '';
};

export default Dashboard;

